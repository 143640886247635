<!-- #Developer by Halil Kılıçarslan -->

<template>
  <header class="grey lighten-4 page-header">
    <app-id-icon />
    <span class="title tertiary--text">{{ title }}</span>
    <v-spacer></v-spacer>
    <v-btn v-if="!hideClose" :disabled="loading" color="grey" icon @click="$emit('close')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </header>
</template>

<script>
  export default {
    name: "AppPageHeader",
    props: {
      title: {
        type: String,
        required: false,
        default: () => "Title"
      },

      hideClose: {
        type: Boolean,
        required: false,
        default: () => false
      },

      loading: {
        type: Boolean,
        required: false,
        default: () => false
      },
      icon: {
        type: String,
        required: false,
        default: () => "mdi-close"
      },
      hideIcon: {
        type: Boolean,
        default: () => false
      },
      tooltip: {
        type: String,
        required: false,
        default: function() {
          return this.$i18n.t("buttons.close");
        }
      }
    }
  };
</script>

<style scoped>
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 10px 14px 20px;
  }
</style>
